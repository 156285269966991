import PropTypes from "prop-types"
import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

// Import necessary Bulma CSS Framework components.
import "./Gallery.library.scss"

// Import component specific styles.
import styles from "./Gallery.module.scss"

const Gallery = props => (
    <div className={styles.galleryBlock + " column is-4"}>
      { props.imageSrc && props.imageAlt && (
        <Img className={styles.galleryImage} fluid={props.imageSrc} title={props.imageTitle} alt={props.imageAlt} />
      )}
      <h2 className={styles.galleryTitle}>{props.title}</h2>
      { props.description && (
        <div
          className={styles.galleryDescription}
          dangerouslySetInnerHTML={{
            __html: props.description
        }} />
      )}
      { props.ctaLink && (
        <Link className={styles.ctaWrapper} to={`/${props.ctaLink}`} aria-label={'Navigate to ' + props.ctaText}>
          <button className={styles.cta}>{props.ctaText}</button>
        </Link>
      )}
    </div>
)

Gallery.propTypes = {
  imageSrc: PropTypes.object,
  imageAlt: PropTypes.string,
  imageTitle: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  ctaLink: PropTypes.string,
  ctaText: PropTypes.string,
}

Gallery.defaultProps = {
  imageSrc: {},
  imageAlt: ``,
  imageTitle: ``,
  title: ``,
  description: ``,
  ctaLink: ``,
  ctaText: ``,
}

export default Gallery
