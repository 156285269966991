import PropTypes from "prop-types"
import React, { useState } from "react"
import GridContainer from "../layout/GridContainer/GridContainer"
import { Link } from "gatsby"
import Img from "gatsby-image"
import ExtendedIcons from "../ExtendedIcons/ExtendedIcons"
import { IoIosClose } from "react-icons/io";
import ZoomIcon from "../../images/zoom-icon.svg"

// Import necessary Bulma CSS Framework components.
import "./BeachesActivityListing.library.scss"

// Import component specific styles.
import styles from "./BeachesActivityListing.module.scss"

const ActivityBeachesBlockWrapper = props => {
  const [activityModalView, setActivityModalView] = useState(0)
  return (
    <GridContainer>
      <div className={styles.beachesActivityListingWrapper}>
        <div className="columns">
          <div className="column is-6">
            <h2 className={styles.titlePrimary + " h2Heavy"}>{props.title}</h2>
            <Img fluid={props.imageSrc} alt={props.imageAlt} title={props.imageTitle} className={styles.image}/>
          </div>
          <div className="column is-6">
            <div className={styles.listingInfo}>
              <h2 className={styles.titleSecondary + " h2Heavy"}>{props.title}</h2>
              { props.features
                  ? <div className={activityModalView === 1 ? styles.isActive : styles.notActive}>
                    <ExtendedIcons features={props.features} />
                      <button className={styles.closeButton + " modal-close is-large"} aria-label="close" onClick={() => setActivityModalView(0)}>
                        <IoIosClose />
                      </button>
                    </div>
                  : ''
              }
              { props.features.length > 0 ?
                <button aria-label="View feature details" className={styles.iconsWrapper} onClick={() => (activityModalView === 1) ? setActivityModalView(0) : setActivityModalView(1)}>
                  <div className={styles.icons}>
                    {props.features.map((feature) => {
                      return (
                        <div className={styles.icon}>
                          <Img
                            fixed={feature.icon.fixed}
                            alt={feature.name}
                            title={feature.name}
                          />
                        </div>
                      )
                    }
                    )}
                    <img className={styles.zoomIcon}  title='Click here to view more' alt='Click here to view more' src={ZoomIcon}/>
                  </div>
                </button>
                : ''
              }
              <p className={props.isWalk === true ? styles.description + ' ' + styles.walksDescription : styles.description}>{props.description}</p>
              <div className={styles.activityExtraInfo}>
                { props.start ? <p className={styles.start}><span>Start:</span> {props.start}</p> : ''}
                { props.end ? <p className={styles.end}><span>End:</span> {props.end}</p> : ''}
                { props.distance ? <p className={styles.distance}><span>Distance:</span> {props.distance}</p> : ''}
                { props.time ? <p className={styles.time}><span>Time:</span> {props.time}</p> : ''}
                { props.POI ? <p className={styles.poi}><span>Points of interest:</span> {props.POI}</p> : ''}
                { props.difficulty ? <p className={styles.difficulty}><span>Difficulty:</span> {props.difficulty}</p> : ''}
              </div>
              { props.mapIcon && props.mapText && props.mapLink && props.isWalk
                ? <Link
                    to={props.mapLink}
                    className={styles.mapLink}
                    state={{ prevPath: props.listingSlug }}
                    aria-label={'View map for ' + props.title}
                  >
                  <span className={styles.mapIcon}>
                    {props.mapIcon}
                  </span>
                  <h4>{props.mapText}</h4>
                </Link>
                : ''
                }
              <Link
                className={styles.ctaWrapper}
                to={props.ctaLink}
                state={{ prevPath: props.listingSlug }}
                aria-label={'Learn more about ' + props.title}
              >
                <button className={styles.cta}>{props.ctaText}</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </GridContainer>
  )
}

ActivityBeachesBlockWrapper.propTypes = {
  isWalk: PropTypes.bool,
  imageSrc: PropTypes.array,
  imageAlt: PropTypes.string,
  imageTitle: PropTypes.string,
  features: PropTypes.array,
  distance: PropTypes.string,
  POI: PropTypes.number,
  difficulty: PropTypes.string,
  time: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  ctaText: PropTypes.string,
  ctaLink: PropTypes.string,
  start: PropTypes.string,
  end: PropTypes.string,
  listingSlug: PropTypes.string,
  mapIcon: PropTypes.object,
  mapLink: PropTypes.string,
  mapText: PropTypes.string,
}

ActivityBeachesBlockWrapper.defaultProps = {
  isWalk: false,
  imageSrc: [],
  imageAlt: ``,
  imageTitle: ``,
  features: [],
  distance: ``,
  POI: ``,
  difficulty: ``,
  time: ``,
  title: ``,
  description: ``,
  ctaText: ``,
  ctaLink: ``,
  start: ``,
  end: ``,
  listingSlug: ``,
  mapIcon: {},
  mapLink: ``,
  mapText: ``,
}

export default ActivityBeachesBlockWrapper
