import PropTypes from "prop-types"
import React from "react"
import Img from "gatsby-image"

// Import necessary Bulma CSS Framework components.
import "./ExtendedIcons.library.scss"

// Import component specific styles.
import styles from "./ExtendedIcons.module.scss"

const ExtendedIcons = props => {
  let featuresFirstHalf = []
  let featuresSecondHalf = []

  if (props.features.length) {
    const splitIndex = Math.floor(props.features.length / 2)
    featuresFirstHalf = props.features.slice(0, splitIndex);
    featuresSecondHalf = props.features.slice(splitIndex, props.features.length);
  }
  return (
    <div className={styles.iconOverlay + " " + styles.icons}>
      <p className={styles.iconOverlayTitle + " listTitleSmall"}>What's Here</p>
      <div className="columns">
        <div className={styles.iconColumn + " column is-6"}>

          {featuresFirstHalf.map((feature, index) => {
            return (
              <div
                className={styles.iconsRow}
                key={feature.name + index}
              >
                <Img
                  fixed={feature.icon.fixed}
                  alt={feature.name}
                  title={feature.name}
                />
                <p className={styles.iconOverlayIconName + " subNav"}>
                  {feature.name}
                </p>
              </div>

            )
          })}
        </div>
        <div className={styles.iconColumn + " column is-6"}>

        {featuresSecondHalf.map((feature, index) => {
            return (
              <div
                className={styles.iconsRow}
                key={feature.name + index}
              >
               <Img
                  fixed={feature.icon.fixed}
                  alt={feature.name}
                  title={feature.name}
                />
                <p className={styles.iconOverlayIconName + " subNav"}>
                  {feature.name}
                </p>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

ExtendedIcons.propTypes = {
  features: PropTypes.array,
}

ExtendedIcons.defaultProps = {
  features: null,
}

export default ExtendedIcons
