import React from "react"
import PropTypes from "prop-types"
import ImageTextBlockRight from "../ImageTextBlockRight/ImageTextBlockRight"
import ImageTextBlockLeft from "../ImageTextBlockLeft/ImageTextBlockLeft"


const Editorial = (props) => {
  if (props.content.layout === 'image-left-text-right') {
    return (
      <ImageTextBlockLeft
        blockImageSrc={props.content.image.fluid}
        blockImageAlt={props.content.image.alt}
        blockImageTitle={props.content.image.title}
        title={props.content.title}
        description={props.content.body}
        ctaText={props.content.linkLabel ? props.content.linkLabel : props.content.link ? props.content.link.title : null}
        ctaLink={props.content.link ? props.content.link.slug : ''}
      />
    )
  } else {
    return (
      <ImageTextBlockRight
        blockImageSrc={props.content.image.fluid}
        blockImageAlt={props.content.image.alt}
        blockImageTitle={props.content.image.title}
        title={props.content.title}
        description={props.content.body}
        ctaText={props.content.linkLabel ? props.content.linkLabel : props.content.link ? props.content.link.title : null}
        ctaLink={props.content.link ? props.content.link.slug : ''}
      />
    )
  }
}

Editorial.propTypes = {
  content: PropTypes.shape({
    __typename: PropTypes.string,
    body: PropTypes.string,
    link: PropTypes.shape({
      __typename: PropTypes.string,
      id: PropTypes.string,
      slug: PropTypes.string,
      title: PropTypes.string,
    }),
    linkLabel: PropTypes.string,
    layout: PropTypes.string,
    image: PropTypes.shape({
      alt: PropTypes.string,
      title: PropTypes.string,
      url: PropTypes.string
    })
  })
}

Editorial.defaultProps = {
  content: {}
}

export default Editorial
