import PropTypes from "prop-types"
import React from "react"
import GridContainer from "../layout/GridContainer/GridContainer"
import Gallery from "./Gallery/Gallery"

// Import necessary Bulma CSS Framework components.
import "./Gallery/Gallery.library.scss"
import styles from  "./MultiImageTextBlock.module.scss"

const MultiImageTextBlock = (props) => (
  <div className={styles.multiImageTextBlock}>
    <GridContainer>
      <div className="columns">
        <Gallery
          imageSrc={props.content.leftCardImage ? props.content.leftCardImage.fluid : ''}
          imageAlt={props.content.leftCardImage ? props.content.leftCardImage.alt : ''}
          imageTitle={props.content.leftCardImage ? props.content.leftCardImage.title : ''}
          title={props.content.leftCardTitle}
          description={props.content.leftCardDescription}
          ctaLink={props.content.leftCardLink ? props.content.leftCardLink.slug : null}
          ctaText={props.content.leftCardLinkLabel ? props.content.leftCardLinkLabel : props.content.leftCardLink ? props.content.leftCardLink.title : null}
        />
        <Gallery
          imageSrc={props.content.middleCardImage ? props.content.middleCardImage.fluid : ''}
          imageAlt={props.content.middleCardImage ? props.content.middleCardImage.alt : ''}
          imageTitle={props.content.middleCardImage ? props.content.middleCardImage.title : ''}
          title={props.content.middleCardTitle}
          description={props.content.middleCardDescription}
          ctaLink={props.content.middleCardLink ? props.content.middleCardLink.slug : null}
          ctaText={props.content.middleCardLinkLabel ? props.content.middleCardLinkLabel : props.content.middleCardLink ? props.content.middleCardLink.title : null}
        />
        <Gallery
          imageSrc={props.content.rightCardImage ? props.content.rightCardImage.fluid : ''}
          imageAlt={props.content.rightCardImage ? props.content.rightCardImage.alt : ''}
          imageTitle={props.content.rightCardImage ? props.content.rightCardImage.title : ''}
          title={props.content.rightCardTitle}
          description={props.content.rightCardDescription}
          ctaLink={props.content.rightCardLink ? props.content.rightCardLink.slug : null}
          ctaText={props.content.rightCardLinkLabel ? props.content.rightCardLinkLabel : props.content.rightCardLink ? props.content.rightCardLink.title : null}
        />
      </div>
    </GridContainer>
  </div>
)

MultiImageTextBlock.propTypes = {
  content: PropTypes.shape({
    id: PropTypes.string,
    leftCardDescription: PropTypes.string,
    leftCardImage: PropTypes.shape({
      alt: PropTypes.string,
      title: PropTypes.string,
      url: PropTypes.string
    }),
    leftCardLinkLabel: PropTypes.string,
    leftCardLink: PropTypes.shape({
      __typename: PropTypes.string,
      id: PropTypes.string,
      slug: PropTypes.string,
      title: PropTypes.string,
    }),
    leftCardTitle: PropTypes.string,
    middleCardDescription: PropTypes.string,
    middleCardImage: PropTypes.shape({
      alt: PropTypes.string,
      title: PropTypes.string,
      url: PropTypes.string
    }),
    middleCardLinkLabel: PropTypes.string,
    middleCardLink: PropTypes.shape({
      __typename: PropTypes.string,
      id: PropTypes.string,
      slug: PropTypes.string,
      title: PropTypes.string,
    }),
    middleCardTitle: PropTypes.string,
    rightCardDescription: PropTypes.string,
    rightCardImage: PropTypes.shape({
      alt: PropTypes.string,
      title: PropTypes.string,
      url: PropTypes.string
    }),
    rightCardLinkLabel: PropTypes.string,
    rightCardLink: PropTypes.shape({
      __typename: PropTypes.string,
      id: PropTypes.string,
      slug: PropTypes.string,
      title: PropTypes.string,
    }),
    rightCardTitle: PropTypes.string,
  })
}

MultiImageTextBlock.defaultProps = {
  content: {}
}

export default MultiImageTextBlock
