import React from "react"
import PropTypes from "prop-types"
import Editorial from "./Editorial"
import MultiImageTextBlock from "../MultiImageTextBlock/MultiImageTextBlock"
import HTMLWrapper from "../HTMLWrapper/HTMLWrapper"
import ArticleListing from "./ArticleListing"
import VideoBlock from "../VideoBlock/VideoBlock"

const UserContent = props => {
  switch (props.content.__typename) {
    case "DatoCmsEditorial":
      return <Editorial content={props.content} key={props.content.id} />

    case "DatoCmsThreeCardList":
      return (
        <MultiImageTextBlock content={props.content} key={props.content.id} />
      )

    case "DatoCmsPlainText":
      return <HTMLWrapper text={props.content.body} />
    case "DatoCmsArticleListing":
      return (
        <ArticleListing
          listingSlug={props.pageSlug}
          content={props.content}
          key={props.content.id}
        />
      )
    case "DatoCmsVideoEmbed":
     return <VideoBlock data={props.content} />

    default:
      console.error("Component does not exist for " + props.content.__typename)
      return null
  }
}

UserContent.propTypes = {
  content: PropTypes.object,
}

UserContent.defaultProps = {
  content: {},
}

export default UserContent
