import React from "react"
import { Link } from "gatsby"
import ActivityBeachesBlockWrapper from "../BeachesActivityListing/BeachesActivityListing"
import { FaMapMarkedAlt } from "react-icons/fa"
import WhatsOn from "../WhatsOn/WhatsOn"
import BusinessListings from "../BusinessListing/BusinessListings"
import Img from "gatsby-image"
import GridContainer from "../layout/GridContainer/GridContainer"

import styles from "./ArticleListing.module.scss"


const ArticleListing = (props) => {
  return (
    <GridContainer>
      <h2 className={styles.title}>{props.content.listTitle}</h2>
      { props.content.listItems.map((listItem, index) => {
        if (listItem.__typename === "DatoCmsPage") {
          return (
            <div key={listItem.id} className={styles.articleWrapper + " columns"}>
              <div className={styles.columns + " column is-6"}>
                {listItem.imageUsedForListingPages && (
                  <Link to={listItem.slug} aria-label={"View " + props.content.listingLinkLabel}>
                    <Img fluid={listItem.imageUsedForListingPages.fluid} alt={listItem.imageUsedForListingPages.alt} title={listItem.imageUsedForListingPages.title} className={styles.articleImage}/>
                  </Link>
                )}
              </div>
              <div className={styles.columns + " column is-6"}>
                <Link to={listItem.slug} aria-label={"View " + props.content.listingLinkLabel}>
                  <h2 className={styles.articleTitle + " h2Heavy"}>{listItem.title}</h2>
                </Link>
                <p dangerouslySetInnerHTML={{__html: listItem.descriptionUsedForListingPages}} className={styles.articleText}></p>
                <Link to={listItem.slug} aria-label={"View " + props.content.listingLinkLabel} className={styles.cta}>
                  {props.content.listingLinkLabel ? props.content.listingLinkLabel : listItem.title}
                </Link>
              </div>
            </div>
          )
        } else if (["DatoCmsPointOfInterest", "DatoCmsJourney"].indexOf(listItem.__typename) !== -1){
          return (
            <ActivityBeachesBlockWrapper
              key={listItem.__typename + index}
              isWalk={listItem.journeyCategory && (listItem.journeyCategory.name === "Walking" || listItem.journeyCategory.name === "Hunt") ? true : false}
              imageSrc={listItem.imageUsedForListingPages.fluid}
              imageAlt={listItem.imageUsedForListingPages.alt}
              imageTitle={listItem.imageUsedForListingPages.title}
              features={listItem.__typename === "DatoCmsJourney" ? listItem.journeyFeatures : listItem.features}
              distance={listItem.distance ? listItem.distance : null}
              POI={listItem.pointsOfInterest !== null ? listItem.pointsOfInterest : null}
              difficulty={listItem.difficulty ? listItem.difficulty : null}
              time={listItem.estimatedTime}
              title={listItem.title}
              description={listItem.descriptionUsedForListingPages}
              ctaText={props.content.listingLinkLabel ? props.content.listingLinkLabel : 'Learn More'}
              ctaLink={listItem.slug}
              start={listItem.startLocation}
              end={listItem.endLocation}
              listingSlug={props.listingSlug}
              mapIcon={listItem.__typename === "DatoCmsJourney" ? <FaMapMarkedAlt /> : null}
              mapLink={listItem.__typename === "DatoCmsJourney" ? listItem.slug + "/map" : null}
              mapText={listItem.__typename === "DatoCmsJourney" ? "View map" : null}
            />
          )
        } else if (listItem.__typename === "DatoCmsLocalBusiness"){
          let listings = [listItem]
          return (
            <BusinessListings
              key={listItem.__typename + index}
              listings={listings} />
          )
        } else if (listItem.__typename === "DatoCmsEvent") {
          let events = [listItem]
          return (
            <WhatsOn
              key={listItem.__typename + index}
              events={events}/>
          )
        } else {
          return <></>
        }
      })}
    </GridContainer>
  )
}

export default ArticleListing
