import PropTypes from "prop-types"
import React from "react"
import GridContainer from "../layout/GridContainer/GridContainer"

// Import necessary Bulma CSS Framework components.
import "./VideoBlock.library.scss"

// Import component specific styles.
import styles from "./VideoBlock.module.scss"

const VideoBlockLeft = props => {
  const checkUrl = url => {
    const youtubeRegex = /youtu/gi
    if (youtubeRegex.test(url)) {
      return 'youtube'
    } else {
      return 'vimeo'
    }
  }
  const adjustVideoUrl = url => {
    const youtubeShortRegex = /youtu.be/gi
    const youtubeFullRegex = /youtube/gi
    if (youtubeShortRegex.test(url)) {
      const urlSplit = url.split(/[/]/)
      const youtubeUrl = 'https://www.youtube.com/embed/'
      const newYoutubeUrl = youtubeUrl + urlSplit[3]
      return newYoutubeUrl
    } else if (youtubeFullRegex.test(url)) {
      const urlSplit = url.split(/['=']/)
      const youtubeUrl = 'https://www.youtube.com/embed/'
      const newYoutubeUrl = youtubeUrl + urlSplit[1]
      return newYoutubeUrl
    }{
      const urlSplit = url.split(/[/.]/)
      const vimeoUrl = 'https://player.vimeo.com/video/'
      const newVimeoUrl = vimeoUrl + urlSplit[4]
      return newVimeoUrl
    }
  }
  const leftRightClassName = props.data.videoPosition === 'video-on-left' ? styles.left : styles.right
  return (
    <GridContainer>
      <div className={styles.videoTextBlockWrapper}>
        <div className={styles.videoTextBlock}>
          { props.data.videoPosition === 'video-on-left' && (
            <div className="columns">
              {props.data.videoEmbed && (
                <div className={styles.columnVideoLeft + " column is-5"}>
                  <div className={leftRightClassName}>
                    <div className={styles.iframeContainer}>
                      <iframe className={styles.videoIFrame} title={props.data.videoEmbed.title} src={adjustVideoUrl(props.data.videoEmbed.url)} allowFullScreen=""></iframe>
                    </div>
                  </div>
                </div>
              )}
              <div className={styles.columnContentRight + " column"}>
                <div className={checkUrl(props.data.videoEmbed.url) === 'youtube' ? styles.youtubeVideo : styles.vimeoVideo}>
                  <div className={styles.videoTextBlockTitle}>
                    <h2>{props.data.videoTitle}</h2>
                  </div>
                  <div className={styles.description}>
                    <p className={styles.descriptionText}>{props.data.videoDescription}</p>
                  </div>
                </div>
              </div>
            </div>
          )}
          { props.data.videoPosition === 'video-on right' && (
            <div className={styles.reverseColumns + " columns"}>
              {props.data.videoEmbed && (
                <div className={styles.columnVideoRight + " column is-5"}>
                  <div className={leftRightClassName}>
                    <div className={styles.iframeContainer}>
                      <iframe className={styles.videoIFrame} title={props.data.videoEmbed.title} src={adjustVideoUrl(props.data.videoEmbed.url)} allowfullscreen=""></iframe>
                    </div>
                  </div>
                </div>
              )}
              <div className={styles.columnContentLeft + " column"}>
                <div className={checkUrl(props.data.videoEmbed.url) === 'youtube' ? styles.youtubeVideo : styles.vimeoVideo}>
                  <div className={styles.videoTextBlockTitle}>
                    <h2>{props.data.videoTitle}</h2>
                  </div>
                  <div className={styles.description}>
                    <p className={styles.descriptionText}>{props.data.videoDescription}</p>
                  </div>
                </div>
              </div>
            </div>
          )}
          { props.data.videoPosition === 'center-video' && (
            props.data.videoEmbed
              ? <div className={styles.centerVideoWrapper}>
                  <div className={styles.centerVideo + " " + styles.iframeContainer}>
                    <iframe title={props.data.videoEmbed.title} src={adjustVideoUrl(props.data.videoEmbed.url)} allowfullscreen=""></iframe>
                  </div>
                </div>
              : ''
          )}
        </div>
      </div>
    </GridContainer>
  )
}

VideoBlockLeft.propTypes = {
  data: PropTypes.object,
}

VideoBlockLeft.defaultProps = {
  data: {},
}

export default VideoBlockLeft
