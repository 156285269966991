import PropTypes from "prop-types"
import React from "react"
import GridContainer from "../layout/GridContainer/GridContainer"
import { Link } from "gatsby"
import Img from "gatsby-image"

// Import necessary Bulma CSS Framework components.
import "./ImageTextBlockRight.library.scss"

// Import component specific styles.
import styles from "./ImageTextBlockRight.module.scss"

const ImageTextBlockRight = props => (
  <div className={styles.imageTextBlockWrapper}>
    <GridContainer>
      <div className={styles.imageTextBlock}>
        <div className={props.blockImageSrc ? "columns" : ''}>
          { props.blockImageSrc
            ? <div className={styles.column + " column is-5 " + styles.imageMobile}>
              <Img fluid={props.blockImageSrc} alt={props.blockImageAlt} title={props.blockImageTitle} />
            </div>
            : ''
          }
          <div className={props.blockImageSrc ? styles.inlineBlock + " column is-7" : styles.inlineBlock}>
            <div className={styles.imageTextBlockTitle}>
              <h2>{props.title}</h2>
            </div>
            <div className={styles.description}>
              <div className={styles.descriptionText}
                dangerouslySetInnerHTML={{
                __html: props.description,
              }} />
            </div>
            { props.ctaLink && (
              <Link to={`/${props.ctaLink}`} aria-label={'Learn more about ' + props.title}>
                <div className={styles.imageTextBlockCta}>
                  {props.ctaText}
                </div>
              </Link>
              )}
          </div>
          { props.blockImageSrc
            ? <div className={styles.column + " column is-5 " + styles.imageDesktop}>
              <Img fluid={props.blockImageSrc} alt={props.blockImageAlt} title={props.blockImageTitle} />
            </div>
            : ''
          }
        </div>
      </div>
    </GridContainer>
  </ div>
)

ImageTextBlockRight.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  ctaLink: PropTypes.string,
  ctaText: PropTypes.string,
  blockImageSrc: PropTypes.object,
  blockImageAlt: PropTypes.string,
  blockImageTitle: PropTypes.string,
}

ImageTextBlockRight.defaultProps = {
  blockImageSrc: {},
  blockImageAlt: ``,
  blockImageTitle: ``,
  title: ``,
  description: ``,
  ctaLink: ``,
  ctaText: ``,
}

export default ImageTextBlockRight
